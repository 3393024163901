import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"

import {
  MajorColumn,
  MinorColumn,
  FlexWrapMobile,
  Page
} from "../../components/content-elements"
import GalleryListing from "../../components/gallery_listing"
import BlogListing from "../../components/blog_listing"

const BlogsPageTemplate = ({ data, location }) => {
  const { page, blogs, galleries } = data

  return (
    <Layout location={location}>
      <FlexWrapMobile>
        <MinorColumn><GalleryListing data={galleries} columns={2} /></MinorColumn>
        <MajorColumn>
          <Page page={page} seo={true}>

            <BlogListing data={blogs} title={false} columns={3} />

          </Page>
        </MajorColumn>
      </FlexWrapMobile>
    </Layout>
  )
}

export default BlogsPageTemplate

export const pageQuery = graphql`

    query blogPageById($id: String!) {
        ...GalleryListFragment
        ...BlogListFragment
        page: markdownRemark(id: {eq: $id}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(id: {eq: $id}) {
            ...SEOFields
            ...MarketingFields
        }
    }
`